// import h from "../plugins/helperPlugin";
import h from '@/services/helpers'

// import h from '@/services/helpers'

export default {

    methods:{
        incrementCount(){
            h.log("das")
            this.count+=2;
        }
    },
    data(){
        return{
            count:11
        }
    }
}
