


import { createStore } from 'vuex'

// Create a new store instance.
function getUser(){
  let userString = localStorage.getItem("user")
  console.log("IsLogged in im Storage",userString)
  if(userString=="undefined") return false;
  if(typeof(userString)=="undefined") return false;
  if(userString===false) return false;
  let user = JSON.parse(userString)
  if (user !== null && typeof (user) != "undefined") {
      return user;
  }
  return false;
}

function getPromoter(){
  let jsonString = localStorage.getItem("promoter")
  console.log("Pronoter IsLogged in im  Storage",jsonString)
  if(jsonString=="undefined") return false;
  if(typeof(jsonString)=="undefined") return false;
  if(jsonString===false) return false;
  let user = JSON.parse(jsonString)
  if (user !== null && typeof (user) != "undefined") {
    console.log(1)
      return user;
  }
  console.log("2")
  return false;
}

// const user=JSON.parse(localStorage.user)//{hans:"Wurdst"};
export default  createStore({
  state : {
      counter: 0,
      // user:{name:"Clöever"},
      user:getUser(),
      promoter:getPromoter(),
  },
  mutations: {
   // Are availöable in Componentss
      //   isLoggedIn(state){
      //   return state.user!==null
      // },
      resetUser(state){
        state.user=null
      }
  
  },
  actions:{
      // dispatch
      // MEthod. cannot change data in state
      // API, HTTP >then commit mutation

  },
  getters:{
    isLoggedIn(state){
      return state.user!==false
    },
    promoterIsLoggedIn(state){
      return state.promoter!==false
    },

  },
  modules:{

  }
})

