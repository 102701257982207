<template>
<div class="main">
    <h1>Notifications</h1>


   <div id="suchergebnisseFromHeader"></div>
   <hr>
<div>

    <w-button @click="$waveui.notify('Information.')" bg-color="info">
  Notify info
</w-button>



<w-button class="px4"
  @click="dialog.show = true"
  bg-color="primary"
  dark>
  Open dialog
</w-button>

<w-dialog
  v-model="dialog.show"
  title="Terms and conditions"
  persistent

  :width="550">
  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rem dolore delectus,
  quisquam ipsa laudantium esse consequatur itaque similique et eligendi eum voluptas
  odit dolor labore eveniet at vel sequi nostrum.<br /><br />
  Voluptates, aut distinctio esse quia doloribus commodi minima inventore neque sequi
  dolores perspiciatis fugiat. Fuga, reprehenderit sequi veritatis iure magnam excepturi
  aliquid dolore quo amet deserunt asperiores placeat maxime perferendis.

  <template #actions>
    <div class="spacer" />
    <w-button
      class="mr2"
      @click="dialog.show = false;value2=false"
      bg-color="error">
      I disagree
    </w-button>
    <w-button
      @click="dialog.show = false;value2=true"
      bg-color="success">
      I agree
    </w-button>
  </template>
</w-dialog>










<el-switch v-model="value2" active-color="#13ce66" inactive-color="#ff4949">
</el-switch>

<w-switch
  class="ma2"
  v-model="value2"
  :value="true"
  label="On">
</w-switch>

</div>

    <div v-if="value2">

    <button class="btn btn-success" v-on:click="sendNotification('success','')">Success</button>
    <button class="btn btn-danger" v-on:click="sendNotification('error','auth')">Danger</button>
    <button class="btn btn-warning" v-on:click="sendNotification('warn','')">Warning</button>
    </div>


    <el-input-number v-model="num" @change="handleChange" :min="1" :max="10"></el-input-number>

<!-- https://element-plus.org/#/en-US/component/select-v2 -->
 <el-select-v2
    v-model="value"
    filterable
    :options="options"
    placeholder="Please select"
    style="width: 500px;"
    multiple
    collapse-tags
  />

   <el-date-picker
      v-model="value1"
      type="datetime"
      placeholder="Select date and time">
    </el-date-picker>



  <el-upload
  class="upload-demo"
  drag
  action="https://jsonplaceholder.typicode.com/posts/"
  :on-preview="handlePreview"
  :on-remove="handleRemove"
  :file-list="fileList"
  multiple
></el-upload>


<p />
   Store:
   {{store}}
    <div class="row">
        <div class="col-md-6">
            <label>Land1</label>
            <app-autocomplete />
        </div>
        <div class="col-md-6">
            <label >Land2</label>
            <app-autocomplete />
        </div>
    
    
         

    </div>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th>name</th>
                    <th>name</th>
                    <th>name</th>
                    <th>name</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Martin</td>
                    <td>Martin</td>
                    <td>Martin</td>
                    <td>Martin</td>
                </tr>
            </tbody>
        </table>
        {{CCName}}
        {{meinUser}}
        <br>
        <input v-model="CCName" />
        <br>
        <input v-model="meinUser.email"  class="form-control"/>
            
        <click-counter></click-counter>
        <hover-counter></hover-counter>
        <HoverCounter></HoverCounter>
    </div>




</template>
<script>

//  import { defineComponent } from 'vue';
//   import { ElMessageBox } from 'element-plus';


import AppAutocomplete from "../components/AppAutocomplete"
import { inject, onUnmounted, ref } from 'vue'
import ClickCounter from '../components/ClickCounter.vue'
import HoverCounter from '../components/HoverCounter.vue'
// import h from '@/plugins/helperPlugin'
// import h from '@/services/helpers'
export default{
    components:{
        AppAutocomplete,
        ClickCounter,
        HoverCounter
    },
   

    setup(){
        const lodash =inject("lodash")
        const moment =inject("moment")
        const storage =inject("storage")
        const notify =inject("notify")
        const store =inject("store")
        const socket =inject("socket")
        const web =inject("web")
       
       onUnmounted (() => {
        console.log('unsubscripte sockets!')
        socket.off('newdata');
       })
        const initials = ['Germany','a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j']

        var options = Array.from({ length: 1000 }).map((_, idx) => ({
          value: `Option${idx + 1}`,
          label: `${initials[idx % 10]}${idx}`,
        }))
        let value = ref([])
        let value1 = ref([])
        let fileList = ref([])
        let value2 = ref(true)

        let dialog = ref({
    show: false,
    fullscreen: false,
    persistent: false,
    overlayOpacity:0.9,
    persistentNoAnimation: false,
    width: 300,
     transition: 'scale'
  })


        const meinName = ref("Martin")
        const num = ref(0)
        const meinUser = ref({
            email:"cc@cclever.de",
            farbe:"rot"
        })
        
         
        

        socket.on("newdata",(newdata) => {
            console.log("NEWDATA in HOME",newdata)
            // store.state.counter++;
            
        })

        function handleRemove(f1,f2){
            console.log("handleRemove")
            console.log(f1)
            console.log(f2)
        }
        function handleChange(f1,f2){
            console.log("HandleChaneg")
            console.log(f1)
            console.log(f2)
        }
        function handlePreview(f1,f2){
            console.log("handlePreview")
            console.log(f1)
            console.log(f2)
        }
        function Appcal(){
            this.a = 1
            this.b =1,
            this. rand = lodash.random(0, 20000); // an integer between 0 and 5
            this.sum =() => { 
                
                // console.log("a:"+this.a+" rand:"+this.rand)
                return this.a  + this.rand }
        }
       
       
       
        function  sendNotification(type,group){


        
            store.state.counter++;
            // store.methods.decreaseCounter()
            // store.state.counterXS={name:"PEter"}

            var appcal=new Appcal()
            let sum=appcal.sum()
             storage.set("Hui",sum)

     
            web.get("/einsatz/full/924941",function(data){
                 console.log("data.einsatz")
                 console.log(data.einsatz)

                //  ElMessageBox.alert(JSON.stringify(data.einsatz), 'Title', {
                //     confirmButtonText: 'OdK',
                //     callback: (action) => {
                //     console.log("action cxcc",action)
                //     },
                //     });



                let einsatz = data.einsatz;
                 einsatz.auftragsnummern=data.auftragsnummern;
                 einsatz.verkaeufe=data.verkaeufe;
                 einsatz.promoter=data.promoter;

                 socket.emit("newEinsatz",{einsatz:data.einsatz});
             })

            // const rand = lodash.random(0, 20000); // an integer between 0 and 5
            // console.log("random: "+rand);
            const zeit = moment().format("DD.MM.YYYY HH:mm:ss")
            // const jahr="1"
            notify({
                duration:1000,
                ignoreDuplicates:false,
                title: "Es istr ein Fehler aufgetreten: <br /> "+zeit+"<br />Sum: "+appcal.sum(),
                group:group,
                text:type,
                type:type,

                
            })


        }
       
        return {
            sendNotification:sendNotification,
            CCName:meinName,
            meinUser:meinUser,
            store,
            num,
            options,
            value,
            value1,
            value2,
            fileList,
            handleChange,
            handleRemove,
            handlePreview,
            dialog
            

        }
       

    },
     mounted (){
            // alert(1)
        }
}

</script>

<style scoped>
.main{
    margin:10px;
}
footer{
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;


}
    template{
        padding:20px;
    }
     .main{
       margin:20px;
    }

</style>