<template>
  <h4 class="mt0">Scannerliste</h4>
  <!-- {{scannersOnNetwork}} -->
  <div class="fr">
    <button class="btn btn-primary" @click="reload()">Neu laden</button>
    <button class="btn btn-secondary" @click="addNew()"><i class="el-icon-plus"></i></button>
    <input type="text" class="form-control" v-model="scannerdata.network" />
    <button class="btn btn-secondary" @click="scanNetwork()">Scan Networt</button>
  </div>

  <div v-if="isScanningNetwork" style="margin:20px;text-align:center">
    Bitte warten... Das Netzwerk wird nach Scannern durchsucht...
  </div>
  <div v-if="scannersOnNetwork.length > 0">
    <el-table :show-header="true" :stripe="true" :data="scannersOnNetwork" row-class-name="cp" :default-sort="{ prop: 'vorname', order: 'ascending' }" style="width: 100%">
      <!-- <el-table-column type="index" width="50" /> -->
      <el-table-column prop="ip" label="IP-Adresse" sortable></el-table-column>
      <el-table-column prop="port" label="Port" sortable></el-table-column>
      <el-table-column>
        <template #default="scope">
          <el-button v-show="!isInMeinerListe(scope.row.ip)" type="primary" @click="scannerUebernehmen(scope.row)">Scanner Übernehmen</el-button>
          <el-button v-show="isInMeinerListe(scope.row.ip)" type="success" >Scanner bereits in Liste</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>

  <input type="text" v-model="searchText" class="form-control " placeholder="Benutzer filtern" />

  <el-table @cell-click="rowClicked" :show-header="true" :stripe="true" :data="getTabledataComputed" row-class-name="cp" :default-sort="{ prop: 'vorname', order: 'ascending' }" style="width: 100%">
    <!-- <el-table-column type="index" width="50" /> -->
    <el-table-column prop="id" label="ID" sortable></el-table-column>
    <el-table-column prop="name" label="Name" sortable></el-table-column>
    <el-table-column prop="ip" label="IP-Adresse" sortable></el-table-column>
    <el-table-column prop="port" label="Port" sortable></el-table-column>
    <el-table-column prop="audioclient" label="Audio Client" sortable></el-table-column>
    <el-table-column prop="bemerkung" label="Bemerkung"></el-table-column>
    <!-- <el-table-column >
           <template #default="scope">
            <el-button type="secondary"  @click="toggleFavorit(scope.row)" circle icon="el-ico-off"></el-button>
            <el-button type="primary"  @click="toggleFavorit(scope.row)" circle icon="el-icon-star-on"></el-button>
            </template>

        </el-table-column> -->
  </el-table>
  <!-- {{getTabledataComputed}} -->
</template>
<script>
// import MyComponent from '../components/MyComponent'
import { inject, ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
export default {
  components: {},
  setup() {
    const web = inject("web");

    const router = useRouter();

    // const moment=inject("moment")
    // const storage =inject("storage")
    // const moment =inject("moment")

    // let kunden=ref([])
    let table = ref({ headers: [], items: [] });
    let searchText = ref("");
    let currentPage = 0;
    let anzPerPage = ref(10);
    let scannersOnNetwork = ref([]);
    let isScanningNetwork = ref(false);

    let scannerdata=ref({
      //  network:"10.10.0.0/24",
       network:"192.168.0.0/24",
       
       ports:"2111"
     })


    onMounted(() => {
      console.log("1");
      reload();
    });

    function scanNetwork() {

      console.log('scanNetwork')
      isScanningNetwork.value = true;
      web.post("/scannetwork",{scannerdata:scannerdata.value}, function(data) {
        console.log("scannetwork");
        console.log(data);
        isScanningNetwork.value = false;
        scannersOnNetwork.value = data.scanners;
      });
    }

    function reload() {
      var params = {
        currentPage: currentPage,
        anzPerPage: anzPerPage.value,
      };
      web.post("/scanners", params, function(data) {
        console.log(data);
        table.value.items = data.scanners;
      });
    }

    function scannerUebernehmen(row) {
      console.log(row);

      web.post("/scannerUebernehmen", { row: row }, function(data) {
        console.log(data);
        reload();
      });
    }

    function addNew() {
      web.post("/addNewScanner", {}, function(data) {
        router.push("/scanner/" + data.newDoc._id); //, params: { kunde_id: row._id } })
      });
    }
    let getTabledataComputed = computed(() => {
      console.log(searchText.value);

      if (searchText.value.trim() == "") {
        return table.value.items;
      }
      // const regex = new RegExp("/\bcontact\b", 'g' )
      var re = new RegExp(searchText.value, "gi");

      return table.value.items.filter((row) => {
        // var vollerName = row.name+" "+row.nachname
        return row.name.match(re);
      });
    });

    let rowClicked = function(row, column) {
      console.log(row);
      console.log(column);
      if (column.no < 4) {
        router.push("/scanner/" + row._id); //, params: { kunde_id: row._id } })
      }
    };

    function isInMeinerListe(ip) {

      let data=getTabledataComputed.value
      for (let i = 0; i < data.length; i++) {
        const scanner = data[i]
        
        console.log("scanner",scanner)
        console.log("scanner.ip",scanner.ip)
        console.log("ip",ip)
        if (scanner.ip == ip) {
          return true;
        }
      }

      return false;
    }

    function setPage(nummer) {
      console.log(nummer);
      currentPage = nummer; //* anzPerPage.value
      console.log(currentPage);
      reload();
    }

    return {
      table,
      reload,
      getTabledataComputed,
      searchText,
      rowClicked,
      setPage,
      anzPerPage,
      addNew,
      scannersOnNetwork,
      scanNetwork,
      scannerdata,
      scannerUebernehmen,
      isScanningNetwork,
      isInMeinerListe,
    };
  },
};
</script>

<style scoped></style>
