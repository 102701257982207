<!-- MyBook.vue -->
<template>

    <label><b>{{label}}</b></label>
    <textarea 
    :value='value' 
    class="form-control"  
    @input='$emit("update:value", $event.target.value)'
    ></textarea>

</template>

<script>
  import {  } from 'vue'

  export default {
    // name:"MyInput",
    props:['value','label',
    ],
    setup() {
      // console.log("props")
      // console.log(props)

      // expose to template
      return {
      }
    }
  }
</script>


