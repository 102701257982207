<template>
    <header>

        <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <a class="navbar-brand" href="#">
          <img src="@/assets/img/logo.png" style="height:40px" />
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#myNavBar" aria-controls="myNavBar" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>




  <div class="collapse  navbar-collapse" id="myNavBar">
    <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
        <router-link class="nav-link" to="/" data-toggle="collapse"  data-target="#myNavBar">Home</router-link> 
      </li>
      
      <li class="nav-item active">
        <router-link class="nav-link" to="/scanners" data-toggle="collapse" data-target="#myNavBar">Scanners</router-link>
      </li>
      <li class="nav-item active">
        <router-link class="nav-link" to="/users" data-toggle="collapse" data-target="#myNavBar">Users</router-link>
      </li>
      <li class="nav-item active">
        <router-link class="nav-link" to="/settings" data-toggle="collapse" data-target="#myNavBar">Settings</router-link>
      </li>
      
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Menü
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <!-- <router-link class="dropdown-item" to="/" data-toggle="collapse" data-target="#myNavBar" >Home</router-link> -->
          <router-link class="dropdown-item" to="/login">Login</router-link>
          <router-link class="dropdown-item" to="/users">Benutzer</router-link>
           <!-- <router-link class="dropdown-item" to="/friends" data-toggle="collapse" data-target="#myNavBar">Friends</router-link> -->
           <!-- <router-link class="dropdown-item" to="/qrcreator" data-toggle="collapse" data-target="#myNavBar">QR Creator</router-link> -->
           
          <!-- <a class="dropdown-item" href="#" data-toggle="collapse" data-target="#myNavBar" >QR-Codes</a> -->
          <!-- <a class="dropdown-item" href="#"  data-toggle="collapse" data-target="#myNavBar">Another action</a> -->
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="" v-on:click="logout"  data-toggle="collapse" data-target="#myNavBar" >Logout</a>
        </div>
      </li>
    </ul>
    <!-- <form class="form-inline my-2 my-lg-0" @submit="onFormSubmit">
      <input  v-model="suchbegriff"  class="form-control mr-sm-2" type="search" placeholder="Suchbeggriff" aria-label="Search">
      <el-button native-type="submit"  type="primary">Suchen</el-button>
    </form> -->
  </div>
</nav>


<teleport v-if="pleasTeleport" to="#suchergebnisseFromHeader">

 <!-- {{promotorenListe}}  -->

 <w-table class="cctable"

        :headers="[ 
        { label: 'Vorname', key: 'vorname' },
        { label: 'Nachname', key: 'nachname' }
        ]"
        :items="promotorenListe">
   <template #item="{ item,  select, classes }">
    <tr  :class="classes" @click="select">
      <td>{{item.vorname}} </td>
      <td>{{item.nachname}} </td>
    </tr>
  </template>
</w-table>



</teleport>
    </header>

    

</template>








<script>




import router from '../router'
import { useStore  } from 'vuex'
import {ref,inject } from 'vue'

export default{
    setup(){
      const web =inject("web")
      const storage =inject("storage")
      const store = useStore()

      let pleasTeleport = ref(false)
      let suchbegriff = ref("")
      let promotorenListe = ref([])
      let errors =( [])
           
      function onFormSubmit(e){
        // pleasTeleport.value=!pleasTeleport.value;
        

        web.post("/promotersuche",{suchbegriff:this.suchbegriff},(data) =>{
          console.log(data);
          promotorenListe.value=data.promoter
          // router.push('home')
          pleasTeleport.value=true;
        })
        // console.log(e.target)
        e.preventDefault(e);
      }

      

 function logout(){
          // LocalStorage leeren
          storage.delete("promoter")
          storage.delete("user")
          // Den User im Status entfenern
          store.state.user= false
          store.state.promoter=false
          // Umleitun
          router.push('/login')
        }


  // function amILoggedIn(){
  //    return store.getters.isLoggedIn
  //   }
      return{
        promotorenListe,
        // amILoggedIn,
        // counter: computed(() => store.state.counter),
        // user: computed(() => store.state.user),
        // $store : store,
        pleasTeleport,
        suchbegriff,
        errors,
        onFormSubmit,
        logout
      }
    },


    props:{
        'title' :{type:String,default:"NMope"}
    },
    

  
    components:{
        // Button
    }
  
}
</script>

<style scoped>




.router-link-active .router-link-exact-active {
    color:#00ce00;
}

/* nav a:hover, */
 nav a.router-link-active,
 nav a.router-link-exact-active {
   /* background-color: indianred; */
   text-decoration: underline;;
   cursor: pointer;
 }
 nav, .dropdown-menu{
   /* background-color:#fa931d  !important; */
   background-color:#cecece  !important;
   color:#FFF;
 }
 nav >*  a{
   color:#FFF !important;
 }
 

 /** Wird teleportiert, ist aber trotzdem hier */
.cctable{
    border:0px;
}

.cctable td{
    padding:10px
}
</style>