<template>
 



  <h4 class="mt0">Scannerliste</h4>

  <div class="fr"> 
    <input type="text" v-model="scannerdata.network" class="form-control " placeholder="Network 10.10.0.0/24">
    <input type="text" v-model="scannerdata.ports" class="form-control " placeholder="2111">

    <button class="btn btn-secondary" @click="scanNetwork()" >Scan Network</button>    
  </div>


  <div v-if="isScanningNetwork" style="margin:20px;text-align:center">

    Bitte warten... Das Netzwerk wird nach Scannern durchsucht...
    </div>
  <div v-if="scannersOnNetwork.length >0 ">
      <el-table
        :show-header="true"
        :stripe="true"
        :data="scannersOnNetwork"
        row-class-name="cp"
        :default-sort="{prop: 'vorname', order: 'ascending'}"
         
        style="width: 100%">
        <!-- <el-table-column type="index" width="50" /> -->
        <el-table-column prop="ip" label="IP-Adresse" sortable ></el-table-column>
        <el-table-column prop="port" label="Port" sortable ></el-table-column>
        <el-table-column >
           <template #default="scope">
             <a target="_blank" :href="'http://'+scope.row.ip+':'+scope.row.port">http://{{scope.row.ip}}:{{scope.row.port}}</a>
            </template>
        </el-table-column>
        <el-table-column >
           <template #default="scope">
             <input type="text" v-model="scope.row.name" class="form-control " placeholder="">

            </template>
        </el-table-column>
        <el-table-column >
           <template #default="scope">

             <button @click="addScanner(scope.row)" class="btn btn-primary">Hinzufügen</button>
            </template>
        </el-table-column>
    </el-table>
  </div>

  

</template>
<script>

  // import MyComponent from '../components/MyComponent'
  import {inject, ref,onMounted} from 'vue'
  // import {useRouter} from 'vue-router'
  export default {
    components:{},
    setup(){

      const web=inject("web")
      // const router = useRouter()

     
     

     let scannersOnNetwork=ref([])
     let isScanningNetwork=ref(false)
     let scannerdata=ref({
      //  network:"10.10.0.0/24",
       network:"192.168.1.0/24",
       ports:"80,443"
     })


    onMounted(() => {
      console.log("1")
    })


    function scanNetwork(){
    
    isScanningNetwork.value=true
    web.post("/scannetwork",{scannerdata:scannerdata.value}, function(data) {
        console.log("scannetwork")
        console.log(data)
        isScanningNetwork.value=false
        scannersOnNetwork.value = data.scanners
      })
    }
  



    

    return {
     
     scannersOnNetwork,
     scanNetwork,
     scannerdata

      
    };
  }
}
</script>

<style scoped>

</style>