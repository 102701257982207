<template>
  <div class="main">
    <button class="btn btn-success" v-on:click="login()">Login</button>
    <button class="btn btn-success" v-on:click="startScan(1)">Start Scan</button>
    <button class="btn btn-success" v-on:click="startScan(0)">Stop Scan</button>
    <button class="btn btn-success" v-on:click="resetEinmesswerte()">Reset</button>
    <button class="btn btn-success" v-on:click="einmesswerteMitteln()">Mitteln</button>
    <button class="btn btn-success" v-on:click="setScannerNummer(0)">1</button>
    <button class="btn btn-success" v-on:click="setScannerNummer(1)">2</button>
    <!-- <button class="btn btn-success" v-on:click="setScannerNummer(2)">2</button>
    <button class="btn btn-success" v-on:click="setScannerNummer(3)">3</button>
    <button class="btn btn-success" v-on:click="setScannerNummer(4)">4</button>
    <button class="btn btn-success" v-on:click="setScannerNummer(5)">5</button>
    <button class="btn btn-success" v-on:click="setScannerNummer(10)">10</button> -->
    msg/sec: {{ messagesPerSecond }} ScannerNummer: {{ currentScannerNummer }}
    <p />
    <!-- <div id="leinwand"></div> -->
    <canvas id="myCanvas" width="3000" height="1000" style="border:1px solid black"></canvas>
    <p />

    Anz-Werte: {{ coordinates.length }}<br />
    <!-- <table class="table table-striped">
      <tr>
          <th>Grad</th>
          <th>Entfernung</th>
          <th>Steigung</th>
          <th>X</th>
          <th>Y</th>
      </tr>
       <tr :key="coordinate.grad" v-for="coordinate in coordinates"  >
          <td>{{coordinate.grad}}</td>
          <td>{{coordinate.entfernung}}</td>
          <td>{{coordinate.steigung}}</td>
          <td>{{coordinate.x}}</td>
          <td>{{coordinate.y}}</td>
          <td>{{coordinate.ausloesungen}}</td>
      </tr>
  </table>  -->
    <!-- {{coordinates}}<br /> -->
  </div>
</template>

<script>
import { inject, onUnmounted, onMounted, ref, computed } from "vue";
import { useRouter } from "vue-router";

export default {
  components: {},

  setup() {
    const socket = inject("socket");
    const web = inject("web");
    const router = useRouter()
    //    let counter = ref(0)
    let coordinates = ref([[1, 2]]);
    let currentScannerNummer = ref(0);
    let blockData = false;
    let SKALE_FACTOR = 0.2;

    function startScan(value) {
      console.log("XOO");

      if (value == 1) {
        blockData = false;
      } else {
        blockData = true;
      }
      web.post("/startScan", { value: value, scannerNummer: currentScannerNummer.value }, (data) => {
        console.log(data);
      });
    }


    function login() {
      console.log("setRange");

    
      router.push("/login")
    }


    function resetEinmesswerte() {
      web.post("/reset_einmesswerte",{scannernummer:currentScannerNummer.value}, (data) => {
        console.log(data);
      });
    }

    function einmesswerteMitteln() {
      web.post("/einmesswerte_mitteln", (data) => {
        console.log(data);
      });
    }

    // var getPunktFromCoordinate=function(coordinate){

    //     const SKALIERUNGSFAKTOR=0.05
    //     let c=coordinate.entfernung
    //     let steigung=coordinate.steigung
    //     let x = Math.round(Math.sqrt( Math.pow(c,2) / steigung+1  ) * SKALIERUNGSFAKTOR)
    //     let y = Math.round( x*steigung )
    //     console.log("Koordinate:"+x+";"+y);

    //     coordinate.x=coordinate.grad;
    //     coordinate.y=y;
    //     // x+=50
    //     // y+=50
    //     // x=coordinate.grad*4
    //     return {
    //         y:y,
    //         x:x
    //     }
    // }

    // function getSteigung(deg) {
    //     var rad = deg * Math.PI / 180;
    //     return Math.tan(rad);
    // }

    // function getKoordinaten(myDataObject){

    //     let {entfernung,grad} = myDataObject
    //     let x,y,steigung

    //     if( grad==0 ){
    //         x=0
    //         y=entfernung
    //     }

    //     steigung = getSteigung(grad)
    //     y = Math.sqrt(   (Math.pow(entfernung, 2) / (1+ Math.pow(steigung, 2)))   )
    //     x = y * steigung;
    //     x=Math.round(x)
    //     y=Math.round(y)
    //     myDataObject.x=x
    //     myDataObject.y=y
    //     console.log("Koordinaten bei Grad: "+grad+"  \t Entfernung "+entfernung+" \t "+x+" | "+y)
    //     return { x:x, y:y }

    // }

    var drawCounter = 0
    function drawIt(coordinate) {
      // if(coordinate.grad > 2) return;
      // let xxx=99;
      //     if(xxx>10){
      //         return;
      //     }
      if (blockData === true) {
        return;
      }
      let dotColor = coordinate.istBoden?"#0b5b0d":"#0000FF";
      let dotdimension = [2, 2];
      if (coordinate.istAlarm) {
        // console.log("IST Alarm");
        dotdimension = [5, 5];
        if (coordinate.istPutzalarm) {
          dotColor = "#ff7800";
        } else {
          dotColor = "#FF0000";
        }
      }
      // console.log(color)
      // drawCounter++;

      // if(drawCounter%400==0){
      //     console.log(coordinate);
      //     console.log(coordinate.entfernung+" "+parseFloat(coordinate.steigung))
      // }
      //  let {x,y} = getPunktFromCoordinate(coordinate)
      //  let {x1,y2} = getKoordinaten(coordinate)

      let { x, y } = coordinate;

      x = x * SKALE_FACTOR;
      y = y * SKALE_FACTOR;

      // if(coordinate.steigung < 1 ||  coordinate.steigung > 1.1 ){
      //     return;
      // }
      var canvas = document.getElementById("myCanvas");
      var ctx = canvas.getContext("2d");

      if (drawCounter == 0) {
        if (window.innerWidth - 40 > 1600) {
          canvas.width = 1600;
        } else {
          canvas.width = window.innerWidth - 40;
        }

        drawCounter++;
        SKALE_FACTOR = (canvas.width / 1600) * 0.18;
      }
      // ctx.canvas.height = window.innerHeight;

      // var background = new Image();
      // background.src = "/assets/img/canvas-background.jpg";
      // background.onload = function(){
      //     ctx.drawImage(background,0,0);
      // }

      // ctx.scale(10, 10);
      // ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = dotColor;
      ctx.fillRect(y, x, dotdimension[0], dotdimension[1]);
      // ctx.fillText(coordinate.grad+" -  "+Math.round(coordinate.entfernung), y,x);
      if(coordinate.grad%5==0)
      ctx.fillText(coordinate.grad+" "+coordinate.y,y,x);

      // Linine zeichnen
      // ctx.moveTo(0, 0);
      // ctx.strokeStyle = "#00ce00";
      // ctx.lineWidth = 1;
      // ctx.lineTo(y, x);
      // ctx.stroke();

      // var y=coordinate.entfernung/4;
      // Höhe Canvas / Höhe Raum
      //  let skalierungsfaktor_hoehe=400/5000
      // x=y/5
      // let y=coordinate.y * skalierungsfaktor_hoehe;

      // ctx.fillRect(coordinate.x, coordinate.y, 2, 2);
      // ctx.fillRect(coordinate.x2, coordinate.y2, 2, 2);

      // ctx.translate(1.5, 0.5)

      // ctx.lineWidth=1

      // ctx.beginPath();
      // let topAbstand=0
      // while (topAbstand < canvas.height) {
      //     topAbstand+=50
      //     // ctx.font = "10px Comic Sans MS";
      //     // ctx.font = " 8pt Courier";
      //     ctx.font = "12px  Arial";
      //     ctx.fillText(topAbstand/SKALE_FACTOR, 0, topAbstand);

      //     ctx.moveTo(0, topAbstand);
      //     ctx.strokeStyle = "#cecece";
      //     ctx.lineTo(canvas.width, topAbstand);
      //     ctx.stroke();

      // }
      // let leftAbstand=0
      //  while (leftAbstand < canvas.width) {
      //     leftAbstand+=50
      //     // ctx.font = "12px Comic Sans MS";
      //     ctx.fillText( leftAbstand/SKALE_FACTOR,leftAbstand, 10);

      //     ctx.moveTo(leftAbstand,0 );
      //     ctx.strokeStyle = "#cecece";
      //     ctx.lineTo(leftAbstand, canvas.height );
      //     ctx.stroke();
      // }
    }

    function setScannerNummer(nummer) {
      console.log("Scanner.off(scannerdata_" + currentScannerNummer.value)
      socket.off("scannerdata_" + currentScannerNummer.value);
      currentScannerNummer.value = nummer;
      subscribeToScanner("scannerdata_" + currentScannerNummer.value);
    }

    function subscribeToScanner(key) {
      console.log("Subscribung to: " + key);
      socket.on(key, (data) => {
        // console.log(data.length)
        overallCounter++;

        if (blockData === true) {
          return;
        }

        var canvas = document.getElementById("myCanvas");
        var ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        // console.log(data.length);
        for (let i = 0; i < data.length; i++) {
          const coordinate = data[i];
          drawIt(coordinate);
        }
        coordinates.value = data;
        // counter.value++
      });
    }

    let messagesPerSecond = ref(1);
    let overallCounter = 0;

    setInterval(() => {
      if (overallCounter == 0) return;
      // console.log("overallCounter: "+overallCounter)
      messagesPerSecond.value = overallCounter;
      overallCounter = 0;
    }, 1000);

    onUnmounted(() => {
      console.log("unsubscripte sockets!");
      socket.off("scannerdata_" + currentScannerNummer.value);
    });

    onMounted(() => {
      console.log("subscripte sockets!");

      //    socket.on("scanneralarm",(data) => {
      //         console.log("ALARM,",data)
      //     })
      setScannerNummer(currentScannerNummer.value);

      //    socket.on("scannerdata_"+currentScannerNummer.value,(data) => {
      //         // console.log(data.length)
      //         overallCounter++;

      //         if(blockData===true){
      //             return;
      //         }

      //         var canvas = document.getElementById("myCanvas");
      //         var ctx = canvas.getContext("2d");
      //         ctx.clearRect(0, 0, canvas.width, canvas.height);
      //             // console.log(data.length);
      //         for (let i = 0; i < data.length; i++) {
      //             const coordinate = data[i];
      //             drawIt(coordinate)

      //         }
      //         coordinates.value=data
      //         // counter.value++

      //     })
    });

    const coordinates_ordered = computed(() => {
      return coordinates;
      // return window._.orderBy(coordinates.value, 'grad')
    });

    return {
      coordinates,
      drawIt,
      startScan,
      resetEinmesswerte,
      einmesswerteMitteln,
      coordinates_ordered,
      messagesPerSecond,
      currentScannerNummer,
      setScannerNummer,
      login
    };
  },
  mounted() {
    // alert(1)
  },
};
</script>

<style scoped>
.main {
  margin: 20px;
}
canvas {
  background-image: url("/img/canvas-background.jpg");
}

#leinwand {
  width: 1300px;
  height: 500px;
  border: 1px solid black;
  background-image: url("/img/canvas-background.jpg");
}
</style>
