<template>
 


 <el-tabs type="border-card">
    <el-tab-pane label="Stammdaten">

    <el-row :gutter="20">
      <el-col  :xs="24" :sm="12" :md="12" :lg="12">
        {{scanner._id}} V:{{scanner.__v}}
      </el-col>
    </el-row>
    
    <div class="fr fssmall"></div>
    <div class="cb"></div>

<form @submit.prevent="saveClicked('stammdaten')" v-if="scanner && scanner.scanRange">
  

    <el-row :gutter="20">
      <el-col  :xs="12" :sm="12" :md="6" :lg="6"><MyInput v-model:value="scanner.id" label="ID" /></el-col>
      <el-col  :xs="12" :sm="12" :md="6" :lg="6"><MyInput v-model:value="scanner.audioclient" label="AudioClient ID" /></el-col>
     
    </el-row>

    <el-row :gutter="20">
      <el-col :xs="12" :sm="12" :md="6" :lg="6"><MyInput v-model:value="scanner.name" label="Name" /></el-col>
      <el-col :xs="12" :sm="12" :md="6" :lg="6"><MyInput v-model:value="scanner.ip" label="IP-Adresse" /></el-col>
      <el-col :xs="12" :sm="12" :md="6" :lg="6"><MyInput type="number" v-model:value="scanner.port" label="Port" /></el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :xs="12" :sm="12" :md="6" :lg="6"><MyInput v-model:value="scanner.scanRange.start" type="number" label="Scan-Range Start" /></el-col>
      <el-col :xs="12" :sm="12" :md="6" :lg="6"><MyInput v-model:value="scanner.scanRange.ende" type="number" label="Scan-Range Ende" /></el-col>
      <el-col :xs="12" :sm="12" :md="6" :lg="6"><MyInput v-model:value="scanner.bodenwinkel.start" type="number" label="Bodenwinkel Start" /></el-col>
      <el-col :xs="12" :sm="12" :md="6" :lg="6"><MyInput v-model:value="scanner.bodenwinkel.ende" type="number" label="Bodenwinkel Ende" /></el-col>
      <el-col :xs="12" :sm="12" :md="6" :lg="6"><MyInput v-model:value="scanner.wandbreite"  type="number" label="Wandbreite in mm " /></el-col>
      <el-col :xs="12" :sm="12" :md="6" :lg="6"><MyInput v-model:value="scanner.deckenhoehe" type="number" label="Deckenhoehe in mm" /></el-col>


    </el-row>    

  <el-row :gutter="20">
    <el-col :span="24">
      <MyTextarea v-model:value="scanner.bemerkung" label="Bemerkung" />
    </el-col>
  </el-row>

       
   

    <el-row :gutter="20">

      <el-col :xs="24" :sm="12" :md="12" :lg="12">  
        <label class="bold">Active&nbsp; </label>
          <!-- <el-switch
          v-model="scanner.archive"
        >
        </el-switch> -->

          <el-switch
          v-model="scanner.active"
          style="display: block"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="Scanner ist aktiv"
          inactive-text="Scanner ist inaktiv"
        />
    <p />
      </el-col>
  </el-row>

  <el-row :gutter="20">
    <el-col :span="24">
      <el-button type="primary" @click="saveClicked('stammdaten')" >Speichern</el-button>
      <el-button  @click="abbrechenClicked">Abbrechen</el-button>
      
      

    </el-col>
  </el-row>



<el-row :gutter="20" style="margin-top:100px">
    <el-col :span="24">
      
      
      <el-button  @click="deleteScanner()">löschen</el-button>

    </el-col>
  </el-row>


  </form>

    </el-tab-pane>



      <el-tab-pane label="Debug">
        
        <el-button  @click="reload()" icon="el-icon-refresh-right"></el-button>
        <pre>

        {{scanner}}
        </pre>
      </el-tab-pane>

 </el-tabs>



</template>
<script>

  // import MyComponent from '../components/MyComponent'
  import MyInput from '../components/MyInput.vue'
  import MyTextarea from '../components/MyTextarea.vue'
  import {inject, ref, computed, onBeforeMount} from 'vue'
  import {  useRoute,useRouter } from 'vue-router'
  import { ElNotification } from 'element-plus'
  export default {
    components:{MyInput,MyTextarea},
    setup(){
      // alert(window.innerWidth)

      const web=inject("web")
      const route = useRoute()
      const router = useRouter()
      let scanner_id = route.params.scanner_id;
      console.log("scanner_id: "+scanner_id)

      let scanner = ref({})

      onBeforeMount(() => {
          console.log("onBeforeMount")
          // reload()
        })

      




      async function reload(){
       return await  web.post("/scanner",{scanner_id:scanner_id},function(data){
          console.log('scanner',data.scanner)
          scanner.value=data.scanner


          // watch([scanner],(oldVal,newVal) => {
          
          //   console.log("oldval",oldVal)
          //   console.log("newCal",newVal)
          //   saveClicked("stammdaten")
          // },{deep:true})

          
        })
      }
  
reload();
    let getNameFor = computed((rolle) => {

      return rolle;
    })

    


    function deleteScanner(){

      if(!confirm("Sicher?")) return

      web.post("/deleteScanner",{scanner_id:scanner.value._id},function(data){
        console.log(data)
        router.push("/scanners")
      })
    }
    function saveClicked(was){
      console.log("saveClicked",was)
      web.post("/saveScanner",{scanner:scanner.value},function(data){
        console.log("was");
        console.log(was);
        console.log("data");
        console.log(data);
        

        ElNotification({
          title: 'Info',
          message: ('hi', { style: 'color: red' }, 'Der Scanner wurde gespeichert'),
        })

        if(was=="stammdaten"){
          router.push("/scanners")
          // kunde.value=null;
        }
       
      })
    }

    function abbrechenClicked(){
      console.log("abbrechenClicked")
      router.push("/scanners")
    }

  

    return {
     reload,
     scanner,
     abbrechenClicked,
     saveClicked,
     getNameFor,
     deleteScanner
    };
  }
}
</script>

<style scoped>

</style>