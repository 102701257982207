// import set from "lodash.set"
import { } from "vue"
export default {
   
    getUser:function(){
        console.log("Getting User")
        return this.user
    },
     get:function(key){
        let itemString=localStorage.getItem(key)
        if(itemString=="undefined"){
            console.log("itemString",itemString)
            return  false;
        }
        return JSON.parse(localStorage.getItem(key));
    },
    set:function(key,value){
        console.log("SET ITEM 1")
        return localStorage.setItem(key,JSON.stringify(value))
    },
    delete:function(key){
        return localStorage.removeItem(key)
    },
    // isLoggedIn: () => {
    //     let userString = localStorage.getItem("user")
    //     console.log("IsLogged in im STorage",userString)
    //     if(userString=="undefined") return false;
    //     if(typeof(userString)=="undefined") return false;
    //     if(userString===false) return false;
    //     let user = JSON.parse(userString)
    //     if (user !== null && typeof (user) != "undefined") {
    //         return true;
    //     }
    //     return false;
    // },
    // PromoterisLoggedIn: () => {
    //     let userString = localStorage.getItem("user")
    //     console.log("PromoterIsLogged in im STorage",userString)
    //     if(userString=="undefined") return false;
    //     if(typeof(userString)=="undefined") return false;
    //     if(userString===false) return false;
    //     let user = JSON.parse(userString)
    //     if (user !== null && typeof (user) != "undefined") {
    //         return true;
    //     }
    //     return false;
    // },
}