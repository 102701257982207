<template>
<h1>Impress 0.1</h1>
    
</template>






<script>

export default{
    name:"Impressum",
}
</script>

<style scoped>

</style>