<template>
<button class="btn btn-secondary" @click="onReload">neu laden</button>

<button class="btn btn-secondary" @click="onTestseite">testsete</button>

<GChart  type="ComboChart" :data="chartData" :options="chartOptions" />
<GChart  type="AreaChart" :data="chartData" :options="options_stacked"  />




</template>
<script>



// import h from "@/plugins/helperPlugin"
// import h from '@/services/helpers'
import { inject ,ref} from 'vue'

//  const moment =inject("moment")

export default{
  
  components: {
    
    } ,
     setup(){
       
       
       const web =inject("web")
       console.log(web)
       let chartData = ref([
        ["Year", "Sales", "Expenses", "Profit","234","234","234","Schnitt"],
        ["2014", 2000, 400, 200,234,134,234,470],
        ["2015", 1170, 460, 250,234,434,234,920],
        ["2016", 660, 1120, 300,234,634,234,500],
        ["2017", 1030, 540, 350,234,134,234,630],
      ])

     let  options_stacked = ref({
       animation:{
        duration: 300,
        easing: 'out',
      },
      //  series: {
      //         1:             {type: 'line'},
      //         // 5:             {type: 'line'},
      //       //   4:             {type: 'area',isStacked: true,}
      //     },
          isStacked: true,
          height: 300,
          legend: {position: 'top', maxLines: 3},
          vAxis: {minValue: 0},
          colors:[
            '#ff0000',
            '#b10000',
            '#720000',
            '#460000',
            '#F2003C',
            '#C40234',
            '#ED2839',
            '#A50021',
          ],
        })

        let  chartOptions=ref({
          animation:{
            duration: 300,
            easing: 'out',
          },
           vAxis: {title: 'Cups'},
           height:500,
           
          hAxis: {title: 'Month'},
          seriesType: 'bars',
          series: {
              1:             {type: 'line'},
              // 5:             {type: 'line'},
            //   4:             {type: 'area',isStacked: true,}
          },
          colors:[
            '#ea0a8e',
            '#b10000',
            '#720000',
            '#460000',
            '#F2003C',
            '#C40234',
            '#ED2839',
            '#A50021',
          ],
          colorss: ['#264c99', '#a52a0d', '#bf7200',
            '#0c7012', '#720072', '#007294',
            '#b72153', '#4c7f00', '#8a2222',
            '#244a6f', '#723372', '#197f72',
            '#7f7f0c', '#4c2699', '#ac5600',
            '#680505', '#4b0c4d', '#256d49',
            '#3f577c', '#2c2e81', '#895619',
            '#10a017', '#8a0e62', '#d30b79',
            '#754227', '#7e930e', '#1f5969',
            '#4c6914', '#8e7b0e', '#084219',
            '#57270c'
    
          ],
        chart: {
             
          title: "Company Performance",

          subtitle: "Sales, Expenses, and Profit: 2014-2017",
          
        },

      })



       function onReload(){
      console.log("reload");

       web.get("/chartdata",(data) => {
          this.chartData=data.chartdata
      })
    }
    function onTestseite(){
       web.get("/sessiontest",(data) => {
         console.log(data)
      })
    }


       return {
         chartData,
         options_stacked,
         chartOptions,
         onTestseite,
         onReload
         }
     },
    data() {
        return {

     
    };
  }
    
}
</script>

<style scoped>
footer{
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
</style>