<template>

<h1>{{person}}</h1>
<h2>Das ist Überschrift 2</h2>


<input type="text" v-model="person.vorname" />
<input type="text" v-model="person.nachname" />
<button @click="btnPressed()">Abschicken</button>
</template>



<script>
import {inject, ref} from 'vue'
  export default {
    setup(){

      const web=inject("web")

      let person=ref(
      {
        vorname:"Micky",
        nachname:"Thomas"
      }
      )


    function btnPressed(){
      console.log("Die Person ist_:",person.value.nachname)

      web.post("/micky",{person:person.value},function(data){
        console.log(data)
      })
    }

    // console.log(name.value)

    return {
     
     person,
     btnPressed
      
    };
  }
}
</script>






