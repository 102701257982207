// const BASE_URL = "http://10.10.0.25:4000";
// const BASE_URL = "http://localhost:4000";
const BASE_URL = "https://laserscanner.format-c.info";
import axios from 'axios'
import { notify } from "@kyvg/vue3-notification";

export default({
  
    post_await: async (url, data) => {
        const result = await axios.post(BASE_URL + url, data)
        // console.log("result:" ,result)
        if (result.data.error) {
            notify({
                duration: 10000,
                ignoreDuplicates: true,
                title: "Es istr ein Fehler aufgetreten",
                text: result.data.error.message,
                type: "warning",
            });
          return false;
        }
        return result.data
    
      },
    post: (url, data, callback) => {
        axios.post(BASE_URL + url, data)
            .then((response) => {
                console.log("1211")
                if (response.data.error) {
                    notify({
                        duration: 10000,
                        ignoreDuplicates: true,
                        title: "Es istr ein Fehler aufgetreten",
                        text: response.data.error.message,
                        type: "warning",
                    });
                }
                callback(response.data)
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    },


    get: (url, callback) => {
        axios.get(BASE_URL + url, {
            withCredentials: true,
        })
            .then((response) => {
                // console.log(typeof response.data)
                // console.log(response.data)
                if (response.data.error) {
                    console.log("response.data.error.message")
                    console.log(response.data.error.message)
                    notify({
                        duration: 10000,
                        ignoreDuplicates: true,
                        title: "Es ist ein Fehler aufgetreten",
                        text: response.data.error.message,
                        type: "success",

                    });

                }
                callback(response.data)
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    },
    
    // storage : {
    //     get:function(key){
    //         return JSON.parse(localStorage.getItem(key));
    //     },
    //     set:function(key,value){
    //         console.log("SET ITEM 1")
    //         return localStorage.setItem(key,JSON.stringify(value))
    //     },
    //     delete:function(key){
    //         return localStorage.removeItem(key)
    //     }
    //   }




})