<template>
 



  <h4 class="mt0">Benutzer</h4>
  <div class="fr">    <button class="btn btn-default" @click="reload()">Neu laden</button>  </div>

  <input type="text" v-model="searchText" class="form-control " placeholder="Benutzer filtern">


    <el-table
        @cell-click="rowClicked"
        :show-header="true"
        :stripe="true"
        :data="getTabledataComputed"
        row-class-name="cp"
        :default-sort="{prop: 'vorname', order: 'ascending'}"
         
        style="width: 100%">
        <el-table-column type="index" width="50" />
        <el-table-column  prop="nachname" label="name" sortable>
          <template #default="scope" >
            {{scope.row.vorname}}
            {{scope.row.nachname}}
          </template>
        </el-table-column>
        <el-table-column prop="email" label="E-Mail" sortable ></el-table-column>
        <el-table-column prop="role" label="Rolle" sortable ></el-table-column>
        <!-- <el-table-column >
           <template #default="scope">
            <el-button type="secondary"  @click="toggleFavorit(scope.row)" circle icon="el-ico-off"></el-button>
            <el-button type="primary"  @click="toggleFavorit(scope.row)" circle icon="el-icon-star-on"></el-button>
            </template>

        </el-table-column> -->
    </el-table>
<!-- {{getTabledataComputed}} -->

</template>
<script>

  // import MyComponent from '../components/MyComponent'
  import {inject, ref,onMounted,computed} from 'vue'
  import {useRouter} from 'vue-router'
  export default {
    components:{},
    setup(){

      const web=inject("web")

      const router = useRouter()

    // const moment=inject("moment")
    // const storage =inject("storage")
    // const moment =inject("moment")

    // let kunden=ref([])
     let table = ref({headers:[],items:[]})
     let searchText = ref("")
     let currentPage=0;
     let anzPerPage=ref(10)


    onMounted(() => {
      console.log("1")
      reload()
    })


    function reload(){
      var params={
        currentPage:currentPage,
        anzPerPage :anzPerPage.value
      }
      web.post("/users",params,function(data){
        console.log("data aaaaaaaaaaaa")
        console.log(data)
            table.value.items=data.users
            table.value.headers=['Ab','B']
      })
    }
  
    let getTabledataComputed =computed(() => {
      console.log(searchText.value);

    if(searchText.value.trim()==""){
      return table.value.items

    }
      // const regex = new RegExp("/\bcontact\b", 'g' )
      var re = new RegExp(searchText.value, "gi");

      return table.value.items.filter((row) => {
        var vollerName = row.vorname+" "+row.nachname
        return vollerName.match(re) 
      })
    })

    let rowClicked = function(row,column){
      console.log(row)
      console.log(column)
      if(column.no < 4){
        router.push('/user/'+row._id);//, params: { kunde_id: row._id } })
      }

    }

    
    function setPage(nummer){
      console.log(nummer)
      currentPage=nummer //* anzPerPage.value
      console.log(currentPage)
      reload()
    }

    

    return {
     table,
     reload,
     getTabledataComputed,
     searchText,
     rowClicked,
     setPage,
     anzPerPage
      
    };
  }
}
</script>

<style scoped>

</style>