<template>
<div class="main" v-if="settings!=null">
    <button class="btn btn-primary" @click="reload()" >Settings neu laden</button>
    <h1>Settings</h1>
    <button class="btn btn-primary" @click="togglePutzmodus()" v-if="settings.putzmodus==true">Putz-Modus ausschalten</button>
    <button class="btn btn-secondary" @click="togglePutzmodus()" v-if="settings.putzmodus==false">Putz-Modus einschalten</button>

    <hr />
    <button class="btn btn-secondary" @click="startStopAll(true)" >Alle einschalten</button>
    <button class="btn btn-secondary" @click="startStopAll(false)" >Alle ausschalten</button>
        <hr />
    {{settings}}
    <h1>Audio-Clients</h1>
    <table>
        <tr v-for="audioclient in audioclients" v-bind:key="audioclient">
            <td>{{audioclient}}</td>
            <td><button class="btn btn-secondary" @click="testAudioAlarm(audioclient)">TEST</button></td>
        </tr>
    </table>


    </div>




</template>
<script>



import { inject,  onMounted,  ref } from 'vue'
export default{
    components:{
    },
   

    setup(){

       let settings=ref(null)
       let audioclients=ref(null)
       let web = inject('web');



       async function saveSettings(){
        let data = await web.post_await("/setSettings",{settings:settings.value})
        console.log('data',data)
        reload()

       }

       async function reload(){
        let data = await web.post_await("/getSettings",{})
        console.log('data',data)
        settings.value=data.settings
        audioclients.value=data.audioclients

       }
       onMounted(() => {
           reload()
       })

      async  function togglePutzmodus(){
          settings.value.putzmodus = !settings.value.putzmodus
          saveSettings()

       }

       function startStopAll(value){
        web.post("/startStopAll", { value: value }, (data) => {
            console.log(data)
            
        });
       }

       function testAudioAlarm(audioclient){

           web.post("/testAudioAlarm", { audioclient: audioclient }, (data) => {
            console.log(data)
            
        });

       }
        return {
            testAudioAlarm,
            togglePutzmodus,
            settings,
            startStopAll,
            audioclients,
            reload
            

        }
       

    },
     mounted (){
            // alert(1)
        }
}

</script>

<style scoped>
.main{
    margin:10px;
}
footer{
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;


}
    template{
        padding:20px;
    }
     .main{
       margin:20px;
    }

</style>