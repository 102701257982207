<template>
 



 <el-tabs type="border-card">
    <el-tab-pane label="Stammdaten">

    <div class="fr fssmall"> {{user._id}} V:{{user.__v}}</div>
    <div class="cb"></div>

    <el-row :gutter="20">
      <el-col  :xs="24" :sm="12" :md="12" :lg="12"><MyInput v-model:value="user.firma" label="Firma" /></el-col>
      <el-col  :xs="24" :sm="12" :md="12" :lg="12">
        <!-- <MyInput v-model:value="user.kundennummer" label="Kundennummer" /> -->
       <label><b>Rolle</b></label>
                <select v-model="user.role" class="form-control"> 
                  <option :key="element.value" :label="element.label" v-for="element in roleData" :value="element.value"></option>
                </select>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :xs="12" :sm="12" :md="6" :lg="6"><MyInput v-model:value="user.anrede" label="Anrede" /></el-col>
      <el-col :xs="12" :sm="12" :md="6" :lg="6"><MyInput v-model:value="user.vorname" label="Vorname" /></el-col>
      <el-col :xs="12" :sm="12" :md="6" :lg="6"><MyInput v-model:value="user.nachname" label="Nachname" /></el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :xs="12" :sm="12" :md="6" :lg="6"><MyInput v-model:value="user.tel" label="Telefon" /></el-col>
      <!-- <el-col :xs="12" :sm="12" :md="6" :lg="6"><MyInput v-model:value="user.fax" label="Fax" /></el-col> -->
      <el-col :xs="12" :sm="12" :md="6" :lg="6"><MyInput v-model:value="user.mobil" label="Mobil" /></el-col>
      <el-col :xs="12" :sm="12" :md="6" :lg="6"><MyInput v-model:value="user.email" label="E-Mail" /></el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :xs="12" :sm="12" :md="6" :lg="6"><MyInput v-model:value="user.strasse" label="Straße" /></el-col>
      <el-col :xs="12" :sm="12" :md="6" :lg="6"><MyInput v-model:value="user.plz" label="PLZ" /></el-col>
      <el-col :xs="12" :sm="12" :md="6" :lg="6"><MyInput v-model:value="user.ort" label="Ort" /></el-col>
      <el-col :xs="12" :sm="12" :md="6" :lg="6"><MyInput v-model:value="user.land" label="Land" /></el-col>
    </el-row>
  <el-row :gutter="20">
    <el-col :span="24">
      <MyTextarea v-model:value="user.bemerkung" label="Bemerkung" />
    </el-col>
  </el-row>

       

    <h4>Zugangsdaten</h4>
   <el-row :gutter="20">
     <el-col :xs="24" :sm="12" :md="12" :lg="12"><MyInput v-model:value="user.username" label="Username" /></el-col>
     <el-col :xs="24" :sm="12" :md="12" :lg="12"><MyInput v-model:value="user.passwort" label="Passwort" type="password" /></el-col>
   </el-row>

    <el-row :gutter="20">

<el-col :xs="24" :sm="12" :md="12" :lg="12">  
  <label class="bold">Archiv&nbsp; </label>
    <!-- <el-switch
    v-model="user.archive"
  >
  </el-switch> -->

    <el-switch
    v-model="user.archive"
    style="display: block"
    active-color="#13ce66"
    inactive-color="#ff4949"
    active-text="User ist archiviert"
    inactive-text="User ist aktiv"
  />

</el-col>
    </el-row>

  <el-row :gutter="20">
    <el-col :span="24">
      <el-affix position="bottom" :offset="30">
      <el-button type="primary" @click="saveClicked('stammdaten')">Speichern</el-button>
      <el-button type="secondary" @click="abbrechenClicked">Abbrechen</el-button>
      </el-affix>
    </el-col>
  </el-row>
    </el-tab-pane>



      <el-tab-pane label="Debug">
        
        <el-button type="secondary" @click="reload()" icon="el-icon-refresh-right"></el-button>
        <pre>

        {{user}}
        </pre>
      </el-tab-pane>

 </el-tabs>



</template>
<script>

  // import MyComponent from '../components/MyComponent'
  import MyInput from '../components/MyInput.vue'
  import MyTextarea from '../components/MyTextarea.vue'
  import {inject, ref, computed, onBeforeMount} from 'vue'
  import {  useRoute,useRouter } from 'vue-router'
  import { ElNotification } from 'element-plus'
  export default {
    components:{MyInput,MyTextarea},
    setup(){
      // alert(window.innerWidth)

      const web=inject("web")
      const route = useRoute()
      const router = useRouter()
      let user_id = route.params.user_id;
      console.log("user_id: "+user_id)

      let user = ref({})

      let roleData = ref([
        {value:"",label:"Bitte wählen"},
        {value:"admin",label:"Admin"},
        {value:"promoter",label:"Promoter"},
        {value:"gl",label:"gebietsleiter"},
        {value:"doit",label:"DO IT"},
      ])


      onBeforeMount(() => {
          console.log("onBeforeMount")
          reload()
        })

      async function reload(){
       return await  web.post("/user",{user_id:user_id},function(data){
          console.log('Kunde',data.user)
          user.value=data.user
        })
      }
  

    let getNameFor = computed((rolle) => {

      return rolle;
    })

    


    function saveClicked(was){
      console.log("saveClicked",was)
      web.post("/saveUser",{user:user.value},function(data){
        console.log("was");
        console.log(was);
        console.log("data");
        console.log(data);
        

        ElNotification({
          title: 'Info',
          message: ('hi', { style: 'color: red' }, 'Der Benutzer wurde gespeichert'),
        })

        if(was=="stammdaten"){
          router.push("/users")
          // kunde.value=null;
        }
       
      })
    }

    function abbrechenClicked(){
      console.log("abbrechenClicked")
      router.push("/users")
    }



    return {
     reload,
     user,
     abbrechenClicked,
     saveClicked,
     roleData,
     getNameFor,
      
    };
  }
}
</script>

<style scoped>

</style>