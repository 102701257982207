<template>
  <div>
  

  <!-- <qrcode-stream @decode="onDecode" @init="onInit" ></qrcode-stream>
<qrcode-drop-zone></qrcode-drop-zone>
<qrcode-capture></qrcode-capture>
 -->
 <qrcode-stream @decode="onDecode" ></qrcode-stream>
<!-- <qrcode-capture @decode="onDecode" class="mb"></qrcode-capture> -->
<div class="result">
  Result: {{qrData}}
</div>



  </div>
</template>

<script>
// https://www.npmjs.com/package/qrcode-vue3
// import QRCodeVue3 from "qrcode-vue3";
import { ref} from 'vue'
export default ({
  name: 'XXX',
  components: {
  },
  setup() {

    let qrData = ref("")
    
    function onDecode(data) {

      console.log("onDecode")
      console.log(data)
      qrData.value = data
    }
    return {
    qrData,
      onDecode
    }
  }
});
</script>