import {createRouter,createWebHistory} from 'vue-router' 
// import {inject} from 'vue' 

 import Friends from '../views/Friends'
 import Qrcreator from '../views/Qrcreator'
//  import Printer from '../views/Printer'
 import Qrscanner from '../views/Qrscanner'
 import Home from '../views/Home'
 import Settings from '../views/Settings'
 import Startseite from '../views/Startseite'
 import Login from '../views/Login'
 import Impressum from '../views/Impressum'
 import Autocomplete from '../views/Autocomplete'
 import Users from '../views/Users'
import User from '../views/User'
import Scanners from '../views/Scanners'
import Scanner from '../views/Scanner'
import Portscanner from '../views/Portscanner'
import Clever from '../views/Clever'
//  import h from '@/plugins/helperPlugin'
 import h from '@/services/helpers'
import  storage from  "@/services/storage"

 const routes = [
    {
         path:"/friends",
         name:"Frinds",
         component:Friends,
         meta: { requiresAuth: true },
    },
     {
          path:"/qrcreator",
          name:"Qrcreator",
          component:Qrcreator,
          meta: { requiresAuth: false },
     },
     {
          path:"/qrscanner",
          name:"Qrscanner",
          component:Qrscanner,
          meta: { requiresAuth: false },
     },
     {
          path:"/",
          name:"Startseite",
          component:Startseite,
          meta: { requiresAuth: false },
     },
    {
          path:"/home",
          name:"Home",
          component:Home,
          meta: { requiresAuth: true },
     },
     {
          path:"/settings",
          name:"Settings",
          component:Settings,
          meta: { requiresAuth: true },
     },
     {
          path:"/login",
          name:"Login",
          component:Login
     },
     {
          path:"/impressum",
          name:"Impressum",
          component:Impressum,
          meta:{requiresAuth_promoter:true}
     },
     {
          path:"/autocomplete",
          name:"Autocomplete",
          component:Autocomplete
     },{
          path:"/users",
          name:"Users",
          component:Users,
          meta: { requiresAuth: true },
          
       },{
          path:"/clever",
          name:"Clever",
          component:Clever,
          
          
       },{
          path:"/user/:user_id",
          name:"User",
          component:User,
          meta: { requiresAuth: true },
       },{
          path:"/scanners",
          name:"Scanners",
          component:Scanners,
          meta: { requiresAuth: true },
       },{
          path:"/scanner/:scanner_id",
          name:"Scanner",
          component:Scanner,
          meta: { requiresAuth: true },
       },{
          path:"/portscanner",
          name:"Portscanner",
          component:Portscanner,
          meta: { requiresAuth: true },
       }
 ]


 const router = createRouter({
     history:createWebHistory(process.env.BASE_URL),
     routes,
    })
    router.replace({ path: '*', redirect: '/abgleichmaerkte' })

    router.beforeEach((to, from, next) => {

     // console.log("beforeEach",to)
     if (to.matched.some(record => record.meta.requiresAuth_promoter)) {
          console.log("Yeachh. I neede a promoter auth")

          // const storage =inject("storage")
          console.log('storage.get("user"');
          if(storage.get("promoter") ==  null){

               next({ name: 'Login' })
          }else{
               next()
          }
     }


     if (to.matched.some(record => record.meta.requiresAuth)) {
       // this route requires auth, check if logged in
       // if not, redirect to login page.

     //   console.log("123")
       if (!h.isLoggedIn()) {
         next({ name: 'Login' })
       } else {
         next() // go to wherever I'm going
       }
     } else {
       next() // does not require auth, make sure to always call next()!
     }
   })

    export default router