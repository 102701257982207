<!-- MyBook.vue -->
<template>

    <label><b>{{label}}</b></label>
    <input 
    :type="type" 
    :value='value' 
    class="form-control"  
    @input='$emit("update:value", $event.target.value)'
    />

</template>

<script>
  import {  } from 'vue'

  export default {
    // name:"MyInput",
    propss:['value','label','type'],
    props:{
      value:String,
      label:String,
      type:{type:String,default:"text"}
      },
    setup() {
      
    


      // expose to template
      return {
        
      }
    }
  }
</script>


