<template>


 <p />
        




    <footer>
        version: 0.1 |
        <router-link to="/impressum">Impressum</router-link> | 
        <router-link to="/impressum">Rechtliches</router-link>

        <!-- <Button text="Add Task" color="green" @click="onClick()" /> -->
        <!-- <Button text="Update Task" color="blue" /> -->
        <!-- <Button text="Delete Task" color="red" /> -->
    </footer>

</template>

<script>

// import Button from './Button'
export default{


    name:"Footer",
    props:{
        randomValue :{type:Number,default:1}
    }
}
</script>

<style scoped>

footer > a {
    color:#FFF;
}
footer{
    color:#fff;
    justify-content: space-between;
    align-items: center;
    position:fixed;
    bottom:0px;
    background:#999;
    height:30px;
    font-size:12px;
    padding-left:20px;
    width:100%;
    padding-right:20px;
    }
</style>